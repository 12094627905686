/* stylesheet.scss */

@import "../node_modules/bootstrap/scss/functions"; 
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

/* Your customizations here */


@import "../node_modules/bootstrap/scss/bootstrap.scss";

.underline {
  text-decoration: underline;
}

.modal-content {
  width: -moz-fit-content;
}

.ladder-navigator {
  display: flex;
  margin: auto;
  width: 10%;
}

.ladder-navigator > button {
  margin: 0.25rem;
}
  

body {
  margin: 0;
  background-color: $dark;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tooltip {
  max-width: 100%;
  width: fit-content !important;
}

.tooltip-inner {
  max-width: 100% !important;
}

.character-panel {
  max-width: none !important;
  width: fit-content !important;
  width: -moz-fit-content !important;
}

.item-tooltip-name0 {
  background-color: RGB(49,49,49);
  white-space: pre-wrap;
  color: RGB(200,200,190);
}

.item-tooltip-name1 {
  background-color: RGB(26,27,43);
  white-space: pre-wrap;
  color: RGB(135,135,255);
}

.item-tooltip-name2 {
  background-color: RGB(70,46,28);
  white-space: pre-wrap;
  color: RGB(189, 189, 87);
}

.item-tooltip-name3 {
  background-color: RGB(65,24,9);
  white-space: pre-wrap;
  color: RGB(182, 98, 27);
}

.item-tooltip-divider {
  border: 0;
  height: 1px;
  margin-bottom: 3px;
  margin-top: 5px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0));
}

.ladder-character-name {
  padding: 0px !important;
  margin: 0px !important;
  border: none !important;
  vertical-align: top !important;
}

.item-tooltip-properties {
  color: RGB(111,111,111);
}

.item-tooltip-properties7 {
  color: purple;
}

.item-tooltip-properties6 {
  color: yellow;
}

.item-tooltip-properties4 {
  color: blue;
}

.item-tooltip-properties5 {
  color: red;
}

.item-tooltip-properties1 {
  color: RGB(135,135,254);
}

.item-tooltip-properties0 {
  color: white;
}

.item-tooltip-mods {
  color: RGB(135,135,254);
}

.item-tooltip-crafted-mods {
  color: RGB(179,212,235)
}

.item0 {
  background-color: RGBA(200,200,190,0.5);
  display: flex;
  border: 1px solid RGB(200,200,190);
}

.item1 {
  background-color: RGBA(135,135,255, 0.5);
  display: flex;
  border: 1px solid RGB(135,135,255);
}

.item2 {
  background-color: RGBA(189, 189, 87, 0.5);
  display: flex;
  border: 1px solid RGB(189, 189, 87);
}

.item3 {
  background-color: RGBA(65,24,9, 0.5);
  display: flex;
  border: 1px solid RGB(182, 98, 27);
}

.item-image {
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}

.character-items {
  border: 1px solid white;
  padding: 5px;
  background-color: RGBA(0,0,0,0.25);
  display:grid;
  gap: 0.25rem;
  grid-template-rows: repeat(8, 4rem);
  grid-template-columns: repeat(8, 4rem);
  grid-template-areas: 
    "weapon weapon weapon2 helm helm offhand2 offhand offhand"
    "weapon weapon weapon2 helm helm offhand2 offhand offhand" 
    "weapon weapon . bodyarmour bodyarmour amulet offhand offhand" 
    "weapon weapon ring bodyarmour bodyarmour ring2 offhand offhand" 
    ". gloves gloves bodyarmour bodyarmour boots boots ." 
    ". gloves gloves belt belt boots boots ." 
    "flask flask flask flask flask flask flask flask" 
    "flask flask flask flask flask flask flask flask"
}

.gems {
  margin-top: 5px;
  padding: 5px;
  border: 1px solid white;
  background-color: RGBA(0,0,0,0.25);
}

.flasks {
  gap: inherit;
  grid-area: flask;
  grid-template-columns: repeat(5, 4rem);
  display: grid;
  margin: auto;
  height: 100%;
}

.flask {
  height: 100%;
  width: 100%;
}

.weapon {
  grid-area: weapon;
}

.belt {
  grid-area: belt;
}

.weapon2 {
  grid-area: weapon2;
}

.helm {
  grid-area: helm;
}

.offhand2 { 
  grid-area: offhand2;
}

.offhand { 
  grid-area: offhand;
}

.bodyarmour { 
  grid-area: bodyarmour;
}

.boots { 
  grid-area: boots;
}

.gloves { 
  grid-area: gloves;
}

.amulet { 
  grid-area: amulet;
}

.ring2 { 
  grid-area: ring2;
}

.ring { 
  grid-area: ring;
}

.gems > div:last-child {
  border-bottom: none;
}

.gem-group {
  border-bottom: 1px solid white;
  padding: 5px;
}

.gem-group-item-slot {
  float: right;
  color: $secondary;
}

.linked-gems > div {
  border-left: 1px solid $secondary
}

.linked-gems > div:only-child, .linked-gems > div:first-child, .linked-gems > div:last-child {
  border-left: none;
}

.linked-gems > div::before {
  position: relative;
  content: "";
  top: -2px;
  height: 14px;
  width: 15px;
  border-bottom: 1px solid $secondary;
  display: inline-block;
  left: 0px;
  margin-right: 5px;
}

.linked-gems > div:first-child::before, .linked-gems > div:last-child::before {
  height: 20px;
  border-left: 1px solid $secondary;
}

.linked-gems > div:first-child::before {
  top: 14px;
  border-bottom: none;
  border-top: 1px solid $secondary;
}

.linked-gems > div:only-child::before {
  border-left: none;
}

.gem {
  margin-left: 5px;
}